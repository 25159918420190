<script setup lang="ts">
// TODO: does this work?
definePageMeta({ middleware: 'marketing' });

useHead({
  script: [
    {
      type: 'text/javascript',
      src: 'https://app.termly.io/embed.min.js',
      'data-auto-block': 'off',
      'data-website-uuid': '13933328-ce44-40d7-aecb-4ca83e73c891',
    },
  ],
});

const authStore = useAuthStore();
const { testToken } = authStore;
const { isApp, loggedIn } = storeToRefs(authStore);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const userType = computed(() => userData.value?.type);

const route = useRoute();

// TODO: change this to beforeMount to fix duplicate API calls, check that it doesn't cause app flashing
onMounted(async () => {
  await testToken();

  if (loggedIn.value) {
    const postLoginPage = userType.value === 'shipper' ? '/dashboard/post-load' : '/dashboard/loads';
    navigateTo(postLoginPage);
    return;
  }

  if (isApp.value) {
    if (!['/login', '/register', '/forgot-password', '/reset-password'].includes(route.path)) {
      await navigateTo('/login');
    }
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'start_pw' });
});
</script>

<template>
  <div>
    <skip-to />
    <marketing-announcement-bar v-if="!isApp">
      Start your
      <nuxt-link
        to="/register"
        class="underline"
      >
        30-Day Free Trial*
      </nuxt-link>
    </marketing-announcement-bar>
    <marketing-header v-if="!isApp" />
    <main
      id="main"
      role="main"
    >
      <nuxt-page />
    </main>
    <marketing-footer v-if="!isApp" />
  </div>
</template>

<style lang="postcss">
.blog-copy {
  a {
    @apply underline;
  }

  p {
    @apply pb-4;
  }

  h1 {
    @apply text-4xl font-bold;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply text-xl font-bold;
  }

  ul,
  ol {
    @apply pb-4 pl-4;
  }

  li {
    @apply list-disc;
  }
}
</style>
